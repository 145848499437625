var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-view',{attrs:{"title":"Коды стретч-карт","filter":"","create":"","create-title":"Сгенерировать коды","url-create":"/short_code/create","store-module":"short_code","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Дата генерации', value: 'created_at_formatted'},
        { text: 'Начальный номер', value: 'start', width: 1  },
        { text: 'Конечный номер', value: 'end', width: 1  },
        { text: 'Количество кодов', value: 'total', width: 1  },
        { text: 'Время генерации, сек.', value: 'time', width: 1  },
        { text: 'Статус', value: 'status_formatted', width: 1  },
        { text: '', value: 'download', width: 1, sortable: false }
    ],"format":_vm.formatItems}})}
var staticRenderFns = []

export { render, staticRenderFns }